<template>
  <div>
    <div class="flex justify-between px-4 mt-6">
      <div>
        <router-link :to="{ name: 'Lobby' }">
          <img :src="logo" class="w-2/6" />
        </router-link>
      </div>
      <div v-if="$route.name != 'Rewards'">
        <button
          @click.prevent="goToRewards()"
          class="shadow-orange-right-small border border-black bg-nColorYellow rounded-3xl text-black text-center px-2 py-1 uppercase text-base font-ZuumeSemiBold tracking-wider focus:outline-none"
        >
          Rewards
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      logo: this.$store.getters.businessLogo,
    };
  },
  components: {},
  methods: {
    goToRewards() {
      this.$router.push({
        name: "Rewards",
        params: {
          business: this.$route.params.business,
        },
      });
    },
  },
};
</script>

<style scoped></style>