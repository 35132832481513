<template>
  <div class="leaderboard bg-black overflow-scroll pb-20" v-if="leaderboard">
    <Header :to="'lobby'" />
    <div class="flex w-full justify-between pt-4 px-4 items-center">
      <p
        class="font-ZuumeSemiBold text-nColorYellow text-5xl text-shadow-black-right"
      >
        leaderboard
      </p>
      <p
        class="font-InterSemiBold text-nColorYellow text-sm text-shadow-black-right"
      >
        {{ formattedGamePlays }}
      </p>
    </div>
    <div id="leaderboardContent">
      <div v-if="!isEmptyObject(liveGame)">
        <div
          class="bg-nColorGreen pb-6 mx-4 rounded-xl my-8 relative whitespace-nowrap"
        >
          <img
            :src="require('@/assets/images/leaderboard/game-live.svg')"
            class="absolute left-6 -top-3"
          />
          <div class="flex">
            <div class="p-4" v-if="liveGame.leaderboard">
              <div
                class="bg-nColorDarkGray w-28 h-36 rounded-xl font-ZuumeSemiBold text-nColorBrightGreen"
              >
                <div class="text-xl text-center pt-2">
                  Your current<br />Ranking
                </div>
                <div
                  class="text-7xl text-center"
                  v-if="liveGame.leaderboard.customer_position"
                >
                  {{ liveGame.leaderboard.customer_position }}
                </div>
                <div class="text-7xl text-center" v-else>-</div>
              </div>
            </div>
            <div class="pt-4">
              <p class="text-3xl font-ZuumeSemiBold text-nColorDarkGray">
                {{ liveGame.meta.name }}
              </p>
              <div
                class="grid justify-start font-InterSemiBold text-nColorDarkGray text-opacity-90"
              >
                <span class="text-sm"> Game closes in </span>
                <div
                  id="gameClosingTimer"
                  class="flex gap-1 items-center mt-1 font-ZuumeSemiBold"
                >
                  <div
                    class="bg-nColorYellow border border-nColorDarkGray rounded-lg text-3xl w-12 text-center"
                  >
                    <span id="gameClosingTimerM">00</span>
                    <span class="text-sm ml-0.5">m</span>
                  </div>
                  <span class="text-2xl">:</span>
                  <div
                    class="bg-nColorYellow border border-nColorDarkGray rounded-lg text-3xl w-12 text-center"
                  >
                    <span id="gameClosingTimerS">00</span>
                    <span class="text-sm ml-0.5">s</span>
                  </div>
                </div>
              </div>
              <div class="grid">
                <div>
                  <div
                    v-if="
                      liveGame.leaderboard.customer_results &&
                      !liveGame.leaderboard.customer_results.has_played
                    "
                    class="text-sm text-nColorDarkGray font-InterSemiBold pt-2"
                  >
                    You have not played as yet.
                  </div>
                  <div v-else>
                    <div
                      class="text-sm text-nColorDarkGray font-InterSemiBold flex gap-1 pt-2"
                    >
                      <img
                        :src="require('@/assets/images/leaderboard/star.svg')"
                      />
                      Your highest result
                    </div>
                    <div class="px-5 font-InterSemiBold">
                      <p
                        v-if="
                          liveGame.leaderboard.customer_results.highest.score &&
                          liveGame.leaderboard.customer_results.highest.time
                        "
                      >
                        {{
                          liveGame.leaderboard.customer_results.highest.score
                        }}
                        in
                        {{
                          timeTaken(
                            liveGame.leaderboard.customer_results.highest.time
                          )
                        }}
                      </p>
                      <p
                        v-else-if="
                          liveGame.leaderboard.customer_results.highest.score
                        "
                      >
                        {{
                          liveGame.leaderboard.customer_results.highest.score
                        }}
                      </p>
                      <p
                        v-else-if="
                          liveGame.leaderboard.customer_results.highest.time
                        "
                      >
                        {{
                          timeTaken(
                            liveGame.leaderboard.customer_results.highest.time
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div
                @click.prevent="
                  goToGame(liveGame.details.schedule_id, liveGame.meta.slug)
                "
                class="w-10/12 absolute bg-nColorYellow border border-nColorDarkGray rounded-3xl shadow-black-right -bottom-4 left-1/2 transform -translate-x-1/2"
              >
                <p
                  class="text-2xl font-ZuumeSemiBold text-nColorDarkGray px-4 text-center"
                >
                  <span
                    v-if="
                      liveGame.leaderboard.customer_results &&
                      !liveGame.leaderboard.customer_results.has_played
                    "
                  >
                    Play now
                  </span>
                  <span v-else>Play again</span>
                </p>
              </div> -->
            </div>
          </div>
          <div class="px-4" v-if="liveGame.leaderboard.results.length > 0">
            <table class="w-full">
              <thead class="text-left font-InterBold text-sm">
                <tr>
                  <th class="normal-row"></th>
                  <th class="normal-row">Name</th>
                  <th class="normal-row"></th>
                  <th class="normal-row">Score</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in liveGame.leaderboard.results"
                  :key="index"
                  class="text-nColorDarkGray"
                  :class="{ 'highlighted-row': row.player === 'You' }"
                >
                  <td
                    class="font-ZuumeSemiBold text-xl"
                    :class="{
                      'normal-row': row.player != 'You',
                      'you-text': row.player === 'You',
                    }"
                  >
                    {{ index + 1 }}
                  </td>
                  <td
                    class="font-InterBold text-xs"
                    :class="{
                      'normal-row': row.player != 'You',
                      'you-text': row.player === 'You',
                    }"
                  >
                    {{ row.player }}
                  </td>
                  <td
                    class="font-InterBold text-xs"
                    :class="{
                      'normal-row': row.player != 'You',
                      'you-text': row.player === 'You',
                    }"
                  >
                    <div class="flex justify-end">
                      <img
                        :src="require('@/assets/images/winner-trophy.png')"
                        v-if="showTrophy(index + 1, liveGame)"
                      />
                    </div>
                  </td>
                  <td
                    class="font-InterBold text-xs"
                    :class="{
                      'normal-row': row.player != 'You',
                      'you-text': row.player === 'You',
                    }"
                  >
                    {{ row.highest_score.score }}
                    <p v-if="row.highest_score.time" class="font-InterRegular">
                      {{ timeTaken(row.highest_score.time) }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else-if="!isEmptyObject(leaderboardGame)">
        <div
          class="bg-nColorBlue mx-4 rounded-xl mt-8 relative whitespace-nowrap"
        >
          <img
            :src="require('@/assets/images/leaderboard/game-closed.svg')"
            class="absolute left-3 -top-3"
          />
          <div class="flex">
            <div class="p-4">
              <div
                class="bg-nColorDarkGray w-28 h-36 rounded-xl font-ZuumeSemiBold text-nColorBrightBlue"
              >
                <div class="text-xl text-center pt-2">Your <br />Ranking</div>
                <div
                  class="text-7xl text-center"
                  v-if="leaderboardGame.leaderboard.customer_position"
                >
                  {{ leaderboardGame.leaderboard.customer_position }}
                </div>
                <div class="text-7xl text-center" v-else>-</div>
              </div>
            </div>
            <div class="pt-4">
              <p class="text-3xl font-ZuumeSemiBold text-nColorDarkGray">
                {{ leaderboardGame.meta.name }}
              </p>
              <div
                class="grid justify-start font-InterSemiBold text-nColorDarkGray text-opacity-90"
              >
                <span class="text-xs"> Results will be announced in </span>
                <div
                  id="leaderboardTimer"
                  class="flex gap-1 items-center mt-1 font-ZuumeSemiBold"
                >
                  <div
                    class="bg-nColorTimerBlue text-nColorLightBlue rounded-lg text-3xl w-12 text-center"
                  >
                    <span id="leaderboardTimerM">00</span>
                    <span class="text-sm ml-0.5">m</span>
                  </div>
                  <span class="text-2xl">:</span>
                  <div
                    class="bg-nColorTimerBlue text-nColorLightBlue rounded-lg text-3xl w-12 text-center"
                  >
                    <span id="leaderboardTimerS">00</span>
                    <span class="text-sm ml-0.5">s</span>
                  </div>
                </div>
              </div>
              <div class="grid">
                <div
                  v-if="
                    leaderboardGame.leaderboard.customer_results &&
                    !leaderboardGame.leaderboard.customer_results.has_played
                  "
                  class="text-xs text-nColorDarkGray font-InterSemiBold pt-2"
                >
                  You have not played this game.
                </div>
                <div v-else>
                  <div
                    class="text-sm text-nColorDarkGray font-InterSemiBold flex gap-1 pt-2"
                  >
                    <img
                      :src="require('@/assets/images/leaderboard/star.svg')"
                    />
                    Your highest result
                  </div>
                  <div class="px-5 font-InterSemiBold">
                    <p
                      v-if="
                        leaderboardGame.leaderboard.customer_results.highest
                          .score &&
                        leaderboardGame.leaderboard.customer_results.highest
                          .time
                      "
                    >
                      {{
                        leaderboardGame.leaderboard.customer_results.highest
                          .score
                      }}
                      in
                      {{
                        timeTaken(
                          leaderboardGame.leaderboard.customer_results.highest
                            .time
                        )
                      }}
                    </p>
                    <p
                      v-else-if="
                        leaderboardGame.leaderboard.customer_results.highest
                          .score
                      "
                    >
                      {{
                        leaderboardGame.leaderboard.customer_results.highest
                          .score
                      }}
                    </p>
                    <p
                      v-else-if="
                        leaderboardGame.leaderboard.customer_results.highest
                          .time
                      "
                    >
                      {{
                        timeTaken(
                          leaderboardGame.leaderboard.customer_results.highest
                            .time
                        )
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="px-4"
            v-if="leaderboardGame.leaderboard.results.length > 0"
          >
            <table class="w-full">
              <thead class="text-left font-InterBold text-sm">
                <tr>
                  <th class="normal-row"></th>
                  <th class="normal-row">Name</th>
                  <th class="normal-row"></th>
                  <th class="normal-row">Score</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in leaderboardGame.leaderboard.results"
                  :key="index"
                  class="text-nColorDarkGray"
                  :class="{ 'highlighted-row': row.player === 'You' }"
                >
                  <td
                    class="font-ZuumeSemiBold text-xl"
                    :class="{
                      'normal-row': row.player != 'You',
                      'you-text': row.player === 'You',
                    }"
                  >
                    {{ index + 1 }}
                  </td>
                  <td
                    class="font-InterBold text-xs"
                    :class="{
                      'normal-row': row.player != 'You',
                      'you-text': row.player === 'You',
                    }"
                  >
                    {{ row.player }}
                  </td>
                  <td
                    class="font-InterBold text-xs"
                    :class="{
                      'normal-row': row.player != 'You',
                      'you-text': row.player === 'You',
                    }"
                  >
                    <div class="flex justify-end">
                      <img
                        :src="require('@/assets/images/winner-trophy.png')"
                        v-if="showTrophy(index + 1, leaderboardGame)"
                      />
                    </div>
                  </td>
                  <td
                    class="font-InterBold text-xs"
                    :class="{
                      'normal-row': row.player != 'You',
                      'you-text': row.player === 'You',
                    }"
                  >
                    {{ row.highest_score.score }}
                    <p v-if="row.highest_score.time" class="font-InterRegular">
                      {{ timeTaken(row.highest_score.time) }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-for="game in unclaimed" :key="game" class="mb-2">
        <div
          class="bg-nColorLighterGray pb-4 mx-4 rounded-xl mb-8 my-4 relative whitespace-nowrap"
        >
          <div class="flex">
            <div class="p-4">
              <div class="bg-nColorDarkGray px-4 py-2 rounded-xl">
                <lottie-animation
                  path="lotties/game-winner.json"
                  :loop="false"
                  :autoPlay="true"
                  :speed="1"
                  :width="75"
                  :height="75"
                />
              </div>
            </div>
            <div class="pt-4">
              <p class="text-3xl font-ZuumeSemiBold text-nColorDarkGray">
                {{ game.meta.name }}
              </p>
              <p class="font-InterSemiBold text-xs">
                Congratulations! You won!
              </p>
            </div>
          </div>
          <div
            v-if="game.leaderboard.is_winner && !game.leaderboard.claimed"
            @click.prevent="claimFreebie(game.details.schedule_id)"
            class="w-11/12 absolute bg-nColorYellow border border-nColorDarkGray rounded-3xl shadow-black-right -bottom-4 left-4 sparkle-button"
          >
            <p
              class="text-2xl font-ZuumeSemiBold text-nColorDarkGray px-4 text-center"
            >
              <span class="flip-text">{{ flipText }}</span>
            </p>
          </div>
          <div class="px-4 pb-4" v-if="game.leaderboard.results.length > 0">
            <table class="w-full">
              <thead class="text-left font-InterBold text-sm">
                <tr>
                  <th class="normal-row"></th>
                  <th class="normal-row">Name</th>
                  <th class="normal-row"></th>
                  <th class="normal-row">Score</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in game.leaderboard.results"
                  :key="index"
                  class="text-nColorDarkGray"
                  :class="{ 'highlighted-row': row.player === 'You' }"
                >
                  <td
                    class="font-ZuumeSemiBold text-xl"
                    :class="{
                      'normal-row': row.player != 'You',
                      'you-text': row.player === 'You',
                    }"
                  >
                    {{ index + 1 }}
                  </td>
                  <td
                    class="font-InterBold text-xs"
                    :class="{
                      'normal-row': row.player != 'You',
                      'you-text': row.player === 'You',
                    }"
                  >
                    {{ row.player }}
                  </td>
                  <td
                    class="font-InterBold text-xs"
                    :class="{
                      'normal-row': row.player != 'You',
                      'you-text': row.player === 'You',
                    }"
                  >
                    <div class="flex justify-end">
                      <img
                        :src="require('@/assets/images/winner-trophy.png')"
                        v-if="showTrophy(index + 1, game)"
                      />
                    </div>
                  </td>
                  <td
                    class="font-InterBold text-xs"
                    :class="{
                      'normal-row': row.player != 'You',
                      'you-text': row.player === 'You',
                    }"
                  >
                    {{ row.highest_score.score }}
                    <p v-if="row.highest_score.time" class="font-InterRegular">
                      {{ timeTaken(row.highest_score.time) }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-for="(game, index) in otherGames" :key="index" class="mt-4 mb-2">
        <div v-if="gameClosedCheck(game)">
          <div
            class="bg-nColorLBGray mx-4 rounded-2xl relative whitespace-nowrap"
          >
            <div class="flex justify-between items-center p-2">
              <div class="font-ZuumeSemiBold text-xl px-4">
                {{ game.meta.name }}
              </div>
              <div
                class="font-ZuumeRegular text-xl bg-nColorLBLightGray rounded-xl px-2 m-1"
              >
                Closed
              </div>
            </div>
            <div class="px-4 pb-4" v-if="game.leaderboard.results.length > 0">
              <table class="w-full">
                <thead class="text-left font-InterBold text-sm">
                  <tr>
                    <th class="normal-row"></th>
                    <th class="normal-row">Name</th>
                    <th class="normal-row"></th>
                    <th class="normal-row">Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, index) in game.leaderboard.results"
                    :key="index"
                    class="text-nColorDarkGray"
                    :class="{ 'highlighted-row': row.player === 'You' }"
                  >
                    <td
                      class="font-ZuumeSemiBold text-xl"
                      :class="{
                        'normal-row': row.player != 'You',
                        'you-text': row.player === 'You',
                      }"
                    >
                      {{ index + 1 }}
                    </td>
                    <td
                      class="font-InterBold text-xs"
                      :class="{
                        'normal-row': row.player != 'You',
                        'you-text': row.player === 'You',
                      }"
                    >
                      {{ row.player }}
                    </td>
                    <td
                      class="font-InterBold text-xs"
                      :class="{
                        'normal-row': row.player != 'You',
                        'you-text': row.player === 'You',
                      }"
                    >
                      <div class="flex justify-end">
                        <img
                          :src="require('@/assets/images/winner-trophy.png')"
                          v-if="showTrophy(index + 1, game)"
                        />
                      </div>
                    </td>
                    <td
                      class="font-InterBold text-xs"
                      :class="{
                        'normal-row': row.player != 'You',
                        'you-text': row.player === 'You',
                      }"
                    >
                      {{ row.highest_score.score }}
                      <p
                        v-if="row.highest_score.time"
                        class="font-InterRegular"
                      >
                        {{ timeTaken(row.highest_score.time) }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="gameUpcomingCheck(game) && index === firstMatchIndex">
          <div
            class="bg-nColorDarkPink mx-4 rounded-2xl relative whitespace-nowrap flex justify-between items-center"
          >
            <div class="font-ZuumeSemiBold text-xl px-4">
              {{ game.meta.name }}
            </div>
            <div
              class="font-ZuumeRegular text-xl bg-nColorLightPink rounded-xl px-2 m-1 w-36"
            >
              Going live at
              <span class="font-ZuumeSemiBold">{{
                formatFriendlyTime(game.details.start_time)
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        id="noGames"
        class="text-nColorOrange font-ZuumeRegular text-4xl flex justify-center"
        v-if="leaderboard && leaderboard.length == 0"
      >
        <img
          :src="require('@/assets/images/leaderboard/leaderboard-no-games.svg')"
        />
      </div>
    </div>
    <div
      class="refresh-button fixed bottom-0 w-screen bg-nColorOrange rounded-t-2xl z-20"
      v-if="showPlayAgain"
    >
      <div
        class="text-nColorDarkGray font-ZuumeSemiBold text-3xl flex justify-center items-center py-2 gap-2"
        @click.prevent="
          goToGame(liveGame.details.schedule_id, liveGame.meta.slug)
        "
      >
        <p>Play again for higher ranking</p>
      </div>
    </div>
    <div
      class="refresh-button fixed bottom-0 w-screen bg-nColorOrange rounded-t-2xl z-20"
      v-if="showRefresh"
    >
      <div
        class="text-nColorDarkGray font-ZuumeSemiBold text-3xl flex justify-center items-center py-2 gap-2"
        @click.prevent="reloadPage"
        v-if="leaderboard.length"
      >
        <img :src="require('@/assets/images/leaderboard/refresh.svg')" />
        <p>refresh</p>
      </div>
    </div>
    <div
      class="goto-game-button fixed bottom-0 w-screen bg-nColorOrange rounded-t-2xl z-20"
      v-else-if="showNextGame"
    >
      <div
        class="text-nColorDarkGray font-ZuumeSemiBold text-3xl flex justify-center items-center py-2 gap-2"
        @click.prevent="goBackToLobby"
        v-if="leaderboard.length"
      >
        <p>Next Game</p>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Header from "@/components/elements/Header.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import slugs from "@/common/slugs";
import confetti from "canvas-confetti";

export default {
  components: { Header, LottieAnimation },
  data() {
    return {
      leaderboard: null,
      gameCutoffTime: null,
      leaderboardTime: null,
      cutoffTimerStarted: false,
      cutoffIntervalId: null,
      leaderboardTimerStarted: false,
      leaderboardIntervalId: null,
      showPlayAgain: false,
      showRefresh: false,
      showNextGame: false,
      flipText: "Claim Freebie",
      anyCheckPass: false,
      liveGame: {},
      leaderboardGame: {},
      unclaimed: [],
      otherGames: [],
      gamePlays: 0,
      firstMatchIndex: -1,
      slugs,
    };
  },
  created() {
    ApiService.get(apiResource.getLeaderboard, {
      business_slug: this.$route.params.business,
      schedule_id: this.$route.params.schedule_id,
    }).then((data) => {
      this.leaderboard = this.reverseArray(data.data.data);
      this.gamePlays = data.data.data[0].total_gameplays;
      let hasUpcoming = false;
      this.leaderboard.forEach((game) => {
        if (game.game_live) {
          this.showPlayAgain = true;
          this.showRefresh = false;
          this.setGameCutoffDateTime(game.details.cutoff_datetime);
          this.startGameClosingTimer();
          this.liveGame = game;
        } else if (game.leaderboard_timer) {
          this.showPlayAgain = false;
          this.showRefresh = true;
          this.setLeaderboardDateTime(game.details.leaderboard_datetime);
          this.startLeaderboardTimer();
          this.leaderboardGame = game;
        }
        if (game.yet_to_start === true) {
          hasUpcoming = true;
        } else if (
          !game.game_live &&
          !game.leaderboard_timer &&
          game.leaderboard.is_winner &&
          !game.leaderboard.claimed
        ) {
          this.unclaimed.push(game);
        }
        if (
          (!game.game_live &&
            !game.leaderboard_timer &&
            (!game.leaderboard.is_winner ||
              (game.leaderboard.is_winner && game.leaderboard.claimed)) &&
            !game.yet_to_start) ||
          game.yet_to_start
        ) {
          this.otherGames.push(game);
        }
      });
      if (hasUpcoming && !this.showPlayAgain) {
        this.showNextGame = true;
      }
    });
  },
  mounted() {
    setInterval(() => {
      this.flipText =
        this.flipText === "Click to Claim"
          ? "Congratulations!"
          : "Click to Claim";
    }, 1000);
  },
  beforeUnmount() {
    if (this.cutoffTimerStarted) {
      this.clearCutoffTimer();
    }
    if (this.leaderboardTimerStarted) {
      this.clearLeaderboardTimer();
    }
    clearInterval(this.gamePlaysIntervalId);
  },
  methods: {
    timeTaken(seconds) {
      let minutes = Math.floor(seconds / (1000 * 60));
      let extraSeconds = Math.floor((seconds % (1000 * 60)) / 1000);
      let milliseconds = Math.round(seconds % 1000);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
      return minutes + ":" + extraSeconds + ":" + milliseconds;
    },
    setGameCutoffDateTime(dateTime) {
      const dateTimeString = dateTime;
      const [dateString, timeString] = dateTimeString.split("T");
      const [year, month, day] = dateString.split("-");
      const formattedDate = `${year}-${month}-${day}`;
      const [hour, minute, second] = timeString.split(":");
      this.gameCutoffTime = new Date(
        `${formattedDate}T${hour}:${minute}:${second}`
      );
    },
    setLeaderboardDateTime(dateTime) {
      const dateTimeString = dateTime;
      const [dateString, timeString] = dateTimeString.split("T");
      const [year, month, day] = dateString.split("-");
      const formattedDate = `${year}-${month}-${day}`;
      const [hour, minute, second] = timeString.split(":");
      this.leaderboardTime = new Date(
        `${formattedDate}T${hour}:${minute}:${second}`
      );
    },
    getTimeCalculation() {
      const currentTime = new Date();
      const remainingTime = Math.max(0, this.gameCutoffTime - currentTime);
      const hours = Math.floor(remainingTime / (1000 * 60 * 60)).toLocaleString(
        "en-US",
        { minimumIntegerDigits: 2 }
      );
      const minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const seconds = Math.floor(
        (remainingTime % (1000 * 60)) / 1000
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        remainingTime: remainingTime,
      };
    },
    getLeaderboardTimeCalculation() {
      const currentTime = new Date();
      const remainingTime = Math.max(0, this.leaderboardTime - currentTime);
      const hours = Math.floor(remainingTime / (1000 * 60 * 60)).toLocaleString(
        "en-US",
        { minimumIntegerDigits: 2 }
      );
      const minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const seconds = Math.floor(
        (remainingTime % (1000 * 60)) / 1000
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        remainingTime: remainingTime,
      };
    },
    startGameClosingTimer() {
      setTimeout(() => {
        const timerElementM = document.getElementById("gameClosingTimerM");
        const timerElementS = document.getElementById("gameClosingTimerS");
        this.cutoffTimerStarted = true;
        this.cutoffIntervalId = setInterval(() => {
          const data = this.getTimeCalculation();
          timerElementM.innerHTML = data.minutes;
          timerElementS.innerHTML = data.seconds;
          if (data.remainingTime <= 0) {
            window.location.reload();
          }
        }, 1000);
      }, 500);
    },
    startLeaderboardTimer() {
      setTimeout(() => {
        const timerElementM = document.getElementById("leaderboardTimerM");
        const timerElementS = document.getElementById("leaderboardTimerS");
        this.leaderboardTimerStarted = true;
        this.leaderboardIntervalId = setInterval(() => {
          const data = this.getLeaderboardTimeCalculation();
          timerElementM.innerHTML = data.minutes;
          timerElementS.innerHTML = data.seconds;
          if (data.remainingTime <= 0) {
            window.location.reload();
          }
        }, 1000);
      }, 500);
    },
    clearCutoffTimer() {
      clearInterval(this.cutoffIntervalId);
      this.cutoffTimerStarted = false;
    },
    clearLeaderboardTimer() {
      clearInterval(this.leaderboardIntervalId);
      this.leaderboardTimerStarted = false;
    },
    goBackToLobby() {
      this.$router.push({
        name: "Lobby",
        params: {
          business: this.$route.params.business,
        },
      });
    },
    reloadPage() {
      window.location.reload();
    },
    goToGame(scheduleId, game) {
      this.$router.push({
        name: "Game",
        params: {
          business: this.$route.params.business,
          schedule_id: scheduleId,
          game: game,
        },
      });
    },
    claimFreebie(scheduleId) {
      this.showConfetti();
      var parameters = {
        business: this.$route.params.business,
        schedule_id: scheduleId,
      };
      setTimeout(() => {
        let category = this.$store.getters.businessCategory;
        if (category === "bpcl") {
          this.$router.push({
            name: "TableNumber",
            params: parameters,
          });
        } else if (category === "event") {
          /*this.$router.push({
            name: "Freebie",
            params: parameters,
          });*/
          this.$router.push({
            name: "Rewards",
            params: {
              business: this.$route.params.business,
            },
          });
        }
      }, 2000);
    },
    showConfetti() {
      // Configure the confetti options
      const confettiOptions = {
        particleCount: 1500,
        spread: 220,
        origin: { y: 0.7 },
      };

      // Trigger the confetti effect
      confetti(confettiOptions);

      // Stop the confetti after 1 second
      setTimeout(() => {
        confetti.reset();
      }, 2000);
    },
    reverseArray(array) {
      if (Array.isArray(array)) {
        return array.slice().reverse();
      } else {
        return array;
      }
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
    gameClosedCheck(game) {
      let checkPassed = false;
      if (
        !game.game_live &&
        !game.leaderboard_timer &&
        (!game.leaderboard.is_winner ||
          (game.leaderboard.is_winner && game.leaderboard.claimed)) &&
        !game.yet_to_start
      ) {
        checkPassed = true;
      }
      this.anyCheckPass = this.anyCheckPass || checkPassed;
      return checkPassed;
    },
    gameUpcomingCheck(game) {
      let checkPassed = false;
      if (game.yet_to_start) {
        checkPassed = true;
      }
      this.anyCheckPass = this.anyCheckPass || checkPassed;
      return checkPassed;
    },
    formatFriendlyTime(timeString) {
      const [hour, minute] = timeString.split(":");
      let period = "AM";

      let hourNum = parseInt(hour, 10);
      if (hourNum >= 12) {
        period = "PM";
        if (hourNum > 12) {
          hourNum -= 12;
        }
      }

      return `${hourNum}:${minute} ${period}`;
    },
    showTrophy(position, game) {
      if (position <= parseInt(game.details.no_of_winners)) {
        return true;
      }
      return false;
    },
  },
  watch: {
    gameUpcomingCheckResult(newValue) {
      if (newValue && this.firstMatchIndex === -1) {
        this.firstMatchIndex = this.otherGames.findIndex((game) =>
          this.gameUpcomingCheck(game)
        );
      }
    },
  },
  computed: {
    formattedGamePlays() {
      // Format the number with "k" suffix if it's in the thousands
      if (this.gamePlays >= 1000) {
        return (this.gamePlays / 1000).toFixed(2) + "k Game Plays";
      } else {
        return this.gamePlays + " Game Plays";
      }
    },
    gameUpcomingCheckResult() {
      return (
        this.otherGames.findIndex((game) => this.gameUpcomingCheck(game)) !== -1
      );
    },
  },
};
</script>

<style scoped lang="postcss">
.leaderboard {
  height: 100dvh;
}
@keyframes sparkle {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.sparkle-button {
  display: inline-block;
  animation: sparkle 0.75s infinite;
}
@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0deg);
  }
  50% {
    transform: perspective(400px) rotateY(90deg);
  }
  100% {
    transform: perspective(400px) rotateY(0deg);
  }
}

#flip-text {
  animation: flip 2s infinite;
}
.normal-row {
  border-bottom: 1px solid rgba(108, 119, 52, 0.5);
  padding: 8px;
  text-align: left;
}
.highlighted-row {
  @apply bg-nColorYellow;
  padding: 8px;
}
.highlighted-row td {
  padding: 8px;
}
.you-text {
  border-bottom: 1px solid rgba(108, 119, 52, 0.5);
  color: black;
}
</style>
